<script lang="ts" setup>
import emblaCarouselVue from 'embla-carousel-vue';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from 'lucide-vue-next';
import { onMounted } from 'vue';

withDefaults(defineProps<{
  arrowButtonClass?: ClassProp;
  containerClass?: ClassProp;
  loop?: boolean;
  nextArrowButtonClass?: ClassProp;
  prevArrowButtonClass?: ClassProp;
  slideIndex?: number;
}>(), { loop: false, })

const emit = defineEmits<{
  onSlideChange: [slideIndex: number];
}>();

const [
  emblaRef,
  emblaApi,
] = emblaCarouselVue({
  loop: __props.loop,
  slidesToScroll: 'auto',
}, [ WheelGesturesPlugin() ]);

const hasPrev = ref(emblaApi.value?.canScrollPrev());
const hasNext = ref(emblaApi.value?.canScrollNext());

const toggleNavigationButtons = () => {
  if (emblaApi.value) {
    hasPrev.value = emblaApi.value.canScrollPrev();
    hasNext.value = emblaApi.value.canScrollNext();

    emit('onSlideChange', emblaApi.value.selectedScrollSnap());
  }
};

onMounted(() => {
  if (emblaApi.value) {
    emblaApi.value
      .on('select', toggleNavigationButtons)
      .on('init', toggleNavigationButtons)
      .on('reInit', toggleNavigationButtons);
  }
});

const onNext = () => {
  if (emblaApi.value) {
    emblaApi.value.scrollNext();
  }
};

const onPrev = () => {
  if (emblaApi.value) {
    emblaApi.value.scrollPrev();
  }
};

watch(() => __props.slideIndex, (newIndex) => {
  if (emblaApi.value && newIndex !== undefined && newIndex !== emblaApi.value.selectedScrollSnap()) {
    emblaApi.value.scrollTo(newIndex);
  }
});
</script>

<template>
  <div
    ref="emblaRef"
    class="relative"
  >
    <div :class="cn('embla__container flex gap-x-4', containerClass)">
      <slot />
    </div>

    <button
      type="button"
      :class="cn('bg-dark/30 hover:bg-dark/50 absolute left-4 top-1/2 flex size-10 -translate-y-5 items-center justify-center rounded-full text-white backdrop-blur-md transition-all duration-300 hover:scale-110', [hasPrev ? 'scale-1 opacity-100' : 'scale-0 opacity-0'], arrowButtonClass, prevArrowButtonClass)"
      @click="onPrev()"
    >
      <ArrowLeftIcon class="size-5" />
    </button>

    <button
      type="button"
      :class="cn('bg-dark/30 hover:bg-dark/50 absolute right-4 top-1/2 flex size-10 -translate-y-5 items-center justify-center rounded-full text-white backdrop-blur-md transition-all duration-300 hover:scale-110', [hasNext ? 'scale-1 opacity-100' : 'scale-0 opacity-0'], arrowButtonClass, nextArrowButtonClass)"
      @click="onNext()"
    >
      <ArrowRightIcon class="size-5" />
    </button>
  </div>
</template>
